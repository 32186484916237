<template>
  <div>
    <input type="file" ref="fileInput" @change="handleCSVUpload"/>
    <ul>
      <li v-for="(data, index) in challenges" :key="index">{{ JSON.stringify(data) }}</li>
      <br>
      <br>
    </ul>
  </div>
</template>

<script>
import Papa from 'papaparse'
import { answerNewDatas } from '../utils/answerNewDatas'

export default {
  name: 'PlayTourCsvUpload',
  data () {
    return {
      csvData: [],
      challenges: [],
      chapters: []
    }
  },
  methods: {
    handleCSVUpload () {
      const csvFile = this.$refs.fileInput.files[0]
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.csvData = results.data
          this.generateObjects()
          this.generateChapters()
          this.saveToStore()
          this.$router.push({ name: 'createPlayTour' })
        }
      })
    },
    generateObjects () {
      this.csvData.forEach(row => {
        const challenge = {
          chapter_name: this.getValueFromHeaders(row, 'chapter name'),
          name: this.getValueFromHeaders(row, 'task name'),
          briefing: this.getValueFromHeaders(row, 'task briefing'),
          type: this.getValueFromHeaders(row, 'task type'),
          answers: this.generateAnswers(row),
          hint: !row.hint ? null : {
            reveal_after_x_tries: this.getValueFromHeaders(row, 'hint reveal_after_x_tries') ? parseInt(this.getValueFromHeaders(row, 'hint reveal_after_x_tries')) : null,
            reveal_after_x_mins: this.getValueFromHeaders(row, 'hint reveal_after_x_mins') ? parseInt(this.getValueFromHeaders(row, 'hint reveal_after_x_mins')) : null,
            credits: this.getValueFromHeaders(row, 'hint credits') ? parseInt(this.getValueFromHeaders(row, 'hint credits')) : null,
            hint: row.hint
          },
          points: this.getValueFromHeaders(row, 'task points') ? parseInt(this.getValueFromHeaders(row, 'task points')) : null,
          wrong_answer_penalty: this.getValueFromHeaders(row, 'points penalty') ? parseInt(this.getValueFromHeaders(row, 'points penalty')) : null,
          time_penalty: this.getValueFromHeaders(row, 'time penalty') ? parseInt(this.getValueFromHeaders(row, 'time penalty')) : null,
          lose_x_points_per_min: this.getValueFromHeaders(row, 'lose x points per min') ? parseInt(this.getValueFromHeaders(row, 'lose x points per min')) : null,
          lose_x_points_per_x_secs: this.getValueFromHeaders(row, 'lose x points per x secs') ? parseInt(this.getValueFromHeaders(row, 'lose x points per x secs')) : null,
          limit_to_x_tries: this.getValueFromHeaders(row, 'limit to x tries') ? parseInt(this.getValueFromHeaders(row, 'limit to x tries')) : null,
          skippable: this.getValueFromHeaders(row, 'skippable') === 'yes',
          skip_penalty: this.getValueFromHeaders(row, 'skip penalty'),
          skippable_after_x_tries: this.getValueFromHeaders(row, 'skippable after x tries'),
          skippable_after_x_mins: this.getValueFromHeaders(row, 'skippable after x mins'),
          item: this.getValueFromHeaders(row, 'item'),
          item_category: this.getValueFromHeaders(row, 'item category'),
          show_specific_players_only: this.getValueFromHeaders(row, 'show specific players only'),
          custom_completion_message: this.getValueFromHeaders(row, 'custom completion message'),
          custom_failure_message: this.getValueFromHeaders(row, 'custom failure message'),
          max_completions_per_session: this.getValueFromHeaders(row, 'max completions per session'),
          access_code: this.getValueFromHeaders(row, 'access code'),
          redo_after_x_mins: this.getValueFromHeaders(row, 'redo after x mins'),
          hide_answer_if_not_answered_correctly: this.getValueFromHeaders(row, 'hide answer if not answered correctly'),
          no_stage_points_if_not_answered_correctly: this.getValueFromHeaders(row, 'no stage points if not answered correctly'),
          hide_after_completion: this.getValueFromHeaders(row, 'hide after completion'),
          time_restricted: this.getValueFromHeaders(row, 'time restricted'),
          time_restricted_allow_after: this.getValueFromHeaders(row, 'time restricted allow after'),
          time_restricted_allow_before: this.getValueFromHeaders(row, 'time restricted allow before'),
          isPositionBased: this.getValueFromHeaders(row, 'location based lat') && this.getValueFromHeaders(row, 'location based lon'),
          position: !(this.getValueFromHeaders(row, 'location based lat') && this.getValueFromHeaders(row, 'location based lon')) ? null : {
            lat: parseFloat(this.getValueFromHeaders(row, 'location based lat')),
            lon: parseFloat(this.getValueFromHeaders(row, 'location based lon')),
            radius_in_meters: this.getValueFromHeaders(row, 'location based radius') ? parseInt(this.getValueFromHeaders(row, 'location based radius')) : null,
            shownOnMap: this.getValueFromHeaders(row, 'location based shown on map') === 'yes',
            visibleOutsideRadius: this.getValueFromHeaders(row, 'location based visible outside radius') === 'yes' || this.getValueFromHeaders(row, 'location based allow completion outside radius') === 'yes',
            allowCompletionOutsideRadius: this.getValueFromHeaders(row, 'location based allow completion outside radius') === 'yes'
          },
          powerup_text: this.getValueFromHeaders(row, 'powerup text'),
          powerup_stars: this.getValueFromHeaders(row, 'powerup stars'),
          powerups: this.getValueFromHeaders(row, 'powerups'),
          task_category: this.getValueFromHeaders(row, 'task category'),
          hide_next_challenge: this.getValueFromHeaders(row, 'hide next task')
        }
        this.addCamelCaseKeys(challenge)
        this.challenges.push(challenge)
      })
    },
    addCamelCaseKeys (obj) {
      const camelCaseObj = {}
      for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase())
        camelCaseObj[camelCaseKey] = obj[key]
      }
      Object.assign(obj, camelCaseObj)
    },
    getValueFromHeaders (row, baseHeader) {
      const headers = [
        baseHeader,
        baseHeader.replace(/ /g, '_'),
        baseHeader.replace(/ /g, '')
      ]
      for (const header of headers) {
        if (row[header]) {
          return row[header]
        }
      }
      return null
    },
    generateAnswers (row) {
      const answers = []
      for (const key in row) {
        const type = row['task type']
        const answerObj = answerNewDatas[type] ? JSON.parse(JSON.stringify(answerNewDatas[type])) : null
        let answer = row[key]
        if (
          key.includes('task answer') &&
          answerObj &&
          answer.length > 0
        ) {
          if (type === 'multiple-choice' && answer.charAt(0) === '*') {
            answerObj.is_valid = true
            answer = answer.substring(1)
          }
          if (type === 'number') answer = Number(answer)
          if (type === 'integer') answer = parseInt(answer)
          answerObj.value = answer
          answers.push(answerObj)
        }
      }
      return answers
    },
    generateChapters () {
      this.challenges.forEach(challenge => {
        if (
          !this.chapters.some(chapter => chapter.title === challenge.chapter_name)
        ) {
          this.chapters.push({
            title: challenge.chapter_name,
            briefing: '',
            time_limit_in_minutes: null,
            auto_forward_when_time_is_up: false,
            min_points: null,
            complete_challenges_in_order: true,
            shuffle_challenges: false,
            chapter_background_url: null,
            chapter_music_url: null,
            has_virtual_spaces: false,
            virtual_spaces: null,
            challenges: []
          })
        }

        const chapter = this.chapters.filter(chapter => chapter.title === challenge.chapter_name)[0]
        chapter.challenges.push(challenge)
      })
    },
    saveToStore () {
      const jsonObj = {
        stages: this.chapters
      }
      console.log('jsonObj', jsonObj)
      this.$store.commit('assistantJsonObj', jsonObj)
    }
  }
}
</script>
